import { Controller } from "@hotwired/stimulus"
import React from 'react';
import { createRoot } from 'react-dom/client';
import ReleasesFilter from '../components/releases/ReleasesFilter';

// Connects to data-controller="categories"
export default class extends Controller {
  static values = {
    selectedCategories: Array,
    selectedChangeTypes: Array,
    view: Object,
    project: Object,
    location: String,
    translations: Object,
    changeTypeRollout: Boolean,
  }

  connect() {
    const root = createRoot(this.element);

    return root.render(
      <ReleasesFilter
        categories={this.categoriesValue}
        selectedCategories={this.selectedCategoriesValue}
        selectedChangeTypes={this.selectedChangeTypesValue}
        view={this.viewValue}
        project={this.projectValue}
        location={this.locationValue}
        translations={this.translationsValue}
        changeTypeRollout={this.changeTypeRolloutValue}
      />
    );
  }
}
